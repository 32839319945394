import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function MobileApps() {
    const navigate = useNavigate();
    return (
        <div className='row'>
            <div className="col-12 mb-3">
                <span className="btn btn-primary btn-sm mb-5" onClick={() => navigate("/services")} style={{ float: "right" }}>Back</span>
                <div className="align-items-center bg-light rounded px-md-5 px-3 py-5">

                    <h3 className="text-uppercase mb-3">
                        2. Mobile App Development:
                    </h3>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        1000Projects has emerged as a leading provider of Mobile
                        Applications over the last few years by consistently enhancing
                        the competencies that are vital to introduce innovation in the
                        one technology which has become the center stage in today's
                        world — the rapidly growing mobile space.
                        <br />
                        We have established a strong expertise in the mobile domain by
                        acquiring an extensive know-how and experience of building
                        groundbreaking mobile applications on the major mobile operating
                        systems: iPhone, Android, BlackBerry, Windows Mobile, and J2ME
                        <br />
                        1000Projects mobile portfolio on the following mobile phone
                        platforms clearly reflect the four cornerstones — innovation,
                        vision, originality, and versatility, which are our fundamental
                        success drivers in securing an impressive presence in the mobile
                        world.
                        <br />
                    </p>
                </div>
            </div>
        </div>
    )
}
