import React from "react";
import { Route, Routes } from "react-router-dom";
import Section from "../common/Section";
import ServicesHome from "./ServicesHome";
import ServicesComplete from "./ServicesComplete";
import WebDevelopment from "./item/WebDevelopment";
import MobileApps from "./item/MobileApps";
import Consulting from "./item/Consulting";
import GlobalSourcing from "./item/GlobalSourcing";
import CorporateTraining from "./item/CorporateTraining";
import Staffing from "./item/Staffing";
import img from "../../assets/img/section_3.png"

export default function Services() {
  return (
    <>
      <Section name="Our Services" img={img} />
      <div className="container mt-5">
        <Routes>
          <Route path="/" element={<ServicesComplete />} />
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route path="/mobile-apps" element={<MobileApps />} />
          <Route path="/consulting" element={<Consulting />} />
          <Route path="/global-sourcing" element={<GlobalSourcing />} />
          <Route path="/corporate-training" element={<CorporateTraining />} />
          <Route path="/staffing" element={<Staffing />} />
        </Routes>
      </div>
    </>
  );
}
