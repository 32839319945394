import React from "react";
import { useNavigate } from "react-router-dom";
import I1 from "../../assets/img/about.png";

export default function AboutHome() {
  const path = window.location.pathname;
  const navigate = useNavigate();

  const handleNavigate = (link) => {
    navigate(link);
    window.scrollTo(0, 0);
  };

  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <img className="img-fluid mb-4 mb-lg-0" src={I1} alt="" />
        </div>
        <div className="col-lg-6 aboutus">
          <small>About Us</small>
          <h1 className="mb-4">
            Welcome to 1000Projects:
            <small>(1000Projects IT Technologies India Private Limited)</small>
          </h1>
          <p className="mb-4">
            1000Projects IT Technologies India Private Limited is reputed IT &
            Non - IT consulting and recruitment service organization located at
            Hyderabad, India. It has played the lead role – thriving on
            challenges and riding on the success wave of the corporate globe. It
            is one of the leading Solution Organization, providing Professional
            Human Resource Solutions to the India. We have specialized in
            solving hiring challenges in companies (both Product and Services)
            since 2014 and have partnered with 20+ Companies across the globe.
            1000Projects provides customized recruitment solutions based on
            needs of our client, our bouquet of offerings cover Permanent.
          </p>
          {path === "/" && (
            <button
              onClick={() => handleNavigate("/about")}
              className="btn btn-primary text-uppercase py-3 px-5 mb-5"
            >
              <b>Read More</b>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
