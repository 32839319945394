import React from "react";
import Section from "../common/Section";
import AboutHome from "./AboutHome";
import AboutVision from "./AboutVision";
import AboutDetail from "./AboutDetail";
import ContactRow from "../contact/ContactRow";
import img from "../../assets/img/section_1.png";

export default function About() {
  return (
    <>
      <Section name="About Us" img={img} />
      <AboutHome />
      <AboutVision />
      <AboutDetail />
      <ContactRow />
    </>
  );
}
