import React from "react";
import Section from "../common/Section";
import CareersHome from "./CareersHome";
import CJob from "./CJob";
import img from "../../assets/img/section_5.png"

export default function Careers() {
  const [data, setData] = React.useState(null)
  return (
    <>
      <Section name="Careers" img={img} />
      <div className="container mt-5">
        {!data && <CareersHome setData={setData} />}
        {data && <CJob data={data} />}
      </div>
    </>
  );
}
