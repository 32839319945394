import React from "react";
import axios from "axios";
import ProjectCard from "./ProjectCard";
import NoRecord from "../common/NoRecord";
import Loader from "../common/Loader";

export default function ProjectsCard() {
  const [project, setProject] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    axios.get("https://server.1000projects.com/project/retrieveAll").then((res) => {
      setProject(res.data);
      setLoading(true);
    });
  }, []);

  return (
    <div className="container mt-5">
      {!loading ? (
        <Loader />
      ) : loading && project.length === 0 ? (
        <div className="col-12">
          <NoRecord />
        </div>
      ) : (
        <div className="row">
          {project.map((item, index) => {
            return (
              <ProjectCard
                item={item}
                key={index} />
            );
          })}
        </div>
      )}
    </div>
  );
}
