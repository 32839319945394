import React from "react";

export default function AboutVision() {
  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12">
          <div className="align-items-center bg-light rounded px-md-5 px-3 py-5">
            <h1 className="text-uppercase mb-3">Our Mission and Vision</h1>
            <p style={{ fontSize: "1rem", color: "black" }}>
              1000Projects It Technologies India Private Limited to be 'the
              best' in the industry – a best recruitment purveyor with the
              ‘care' of a small boutique and the deliverance scope of a major
              player. “Big enough to deliver, small enough to care".
              <br />
              We will make a valuable and positive contribution by:
              <ul>
                <li>Working in partnership with our clients and candidates</li>
                <li>Tailoring our services to meet individual needs</li>
                <li>Maintaining integrity and honesty at all times</li>
                <li>Caring about the end result</li>
                <li>
                  Exceeding the expectations of both our clients and our
                  candidates
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
