import React from "react";

export default function InternshipsHome() {
  const path = window.location.pathname;
  const data = [
    {
      name1: "MERN Project",
      img: "https://upload.wikimedia.org/wikipedia/commons/9/94/MERN-logo.png",
    },
    {
      name1: "Python",
      img: "https://logos-world.net/wp-content/uploads/2021/10/Python-Symbol.png",
    },
    {
      name1: "Machine Learning",
      img: "https://www.wi6labs.com/wp-content/uploads/2019/12/Machine-learning-logo-1.png",
    },
    {
      name1: "Data Science",
      img: "https://res.cloudinary.com/hevo/images/f_auto,q_auto/v1626244975/hevo-learn/datascience-1024x511-1/datascience-1024x511-1.png?_i=AA",
    },
    {
      name1: "Artificial Intelligence",
      img: "https://www.pngkey.com/png/full/96-967577_ai-artificial-intelligence-transparent-background.png",
    },
    {
      name1: "Android",
      img: "https://www.freepnglogos.com/uploads/android-logo-png/android-logo-transparent-png-svg-vector-2.png",
    },
    {
      name1: "Web Development",
      img: "https://dreamerinfotech.in/wp-content/uploads/2021/12/web-development.png",
    },
    {
      name1: ".NET ",
      img: "https://www.pngkit.com/png/full/244-2444486_net-icon-net-framework.png",
    },
    {
      name1: "Power BI",
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/New_Power_BI_Logo.svg/2048px-New_Power_BI_Logo.svg.png",
    },
    {
      name1: "SAP BW",
      img: "https://www.seekpng.com/png/detail/228-2285421_sap-logo-png-vector-free-download-sap-logo.png",
    },
    {
      name1: "Salesforce",
      img: "https://assets.stickpng.com/images/58482f67cef1014c0b5e4a81.png",
    },
    {
      name1: "SQL Server",
      img: "https://seeklogo.com/images/M/microsoft-sql-server-logo-96AF49E2B3-seeklogo.com.png",
    },
    {
      name1: "Selenium Testing",
      img: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Selenium_Logo.png",
    },
  ];

  return (
    <div className="container internship py-5">
      {path === "/" && <h1 className="display-4 text-uppercase text-center mb-5">Internships</h1>}
      <div className="row text-center justify-content-center">
        {data.map((item, index) => {
          return (
            <div className="col-md-3 col-12 mb-2">
              <div className="service-item rounded mb-4">
                <div className="align-middle p-2" style={{ height: "120px" }}>
                  <img
                    src={item.img}
                    height="100%"
                    max-width="100%"
                    className="align-middle p-3"
                  />
                </div>

                <h4 className="text-uppercase">
                  {item.name1}{" "}
                  <span className="d-block text-body">Internship</span>
                </h4>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
