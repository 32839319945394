import React from "react";
import axios from "axios";

export default function Application({ post }) {
  const [Alert, setAlert] = React.useState("");
  const [id, setId] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [perc, setPerc] = React.useState(0);
  const [data, setData] = React.useState({
    name: "",
    email: "",
    phone: "",
    post: post,
    resume: "",
  });

  React.useEffect(() => {
    setData({ ...data, post: post });
  }, [post]);

  const handleChange = (e) => {
    setAlert("");
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.name && data.phone.length === 10) {
      if (data.resume) {
        axios
          .post("https://server.1000projects.com/jobApplication/new", data)
          .then((res) => {
            console.log(res);
            if (res.data.id) {
              setAlert("");
              setId(res.data.id);
            }
            setData({
              name: "",
              email: "",
              phone: "",
              post: "",
              resume: "",
            });
            setFile(null);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setAlert("Please upload a resume");
      }
    } else {
      setAlert("Please enter valid details");
    }
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (file) {
      setPerc(perc + 10);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("location", "careers/resume");
      setPerc(perc + 20);
      try {
        const res = await axios.post(
          "https://server.1000projects.com/file/upload",
          formData
        );
        setPerc(100);
        setData((prev) => {
          return {
            ...prev,
            resume: res.data.Location,
          };
        });
        setTimeout(() => {
          setPerc(0);
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    } else {
      setAlert("Please select a file");
    }
  };

  return (
    <>
      <div className="col-12">
        <h5 className="text-center mb-5">
          Fill this Form and we back to you soon.
        </h5>
        {Alert && (
          <div class="alert alert-primary text-center" role="alert">
            {Alert}
          </div>
        )}
        {id && (
          <div class="alert alert-primary text-center" role="alert">
            Thanks for your Application. <br />
            Your Application ID is <b>{id}</b>
            <br />
            We will get back to you soon.
          </div>
        )}
      </div>
      <br />
      <div className="col-12">
        <form>
          <div className="form-row row">
            <div className="col-md-3">
              <div className="control-group">
                <input
                  type="text"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                  className="form-control bg-light border-0 p-4"
                  placeholder="Your Name"
                  required
                />
                <p className="help-block text-danger" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="control-group">
                <input
                  type="email"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                  className="form-control bg-light border-0 p-4"
                  placeholder="Your Email"
                  required
                />
                <p className="help-block text-danger" />
              </div>
            </div>
            <div className="col-md-2">
              <div className="control-group">
                <input
                  type="number"
                  name="phone"
                  value={data.phone}
                  onChange={handleChange}
                  className="form-control bg-light border-0 p-4"
                  placeholder="Your Phone"
                  required
                />
                <p className="help-block text-danger" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="control-group">
                <input
                  type="text"
                  value={post}
                  className="form-control bg-light border-0 p-4"
                  placeholder="Enter Post"
                  readOnly={post ? true : false}
                />
                <p className="help-block text-danger" />
              </div>
            </div>
            <div className="col-md-6 careers-image-upload">
              {!data.resume && (
                <div className="row m-1" id="cforms">
                  <span className="col-8">
                    <label
                      className="fa fa-solid fa-upload"
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        className="form-control btn btn-outline-primary"
                        type="file"
                        onChange={handleFile}
                        name="file"
                        accept="application/pdf application/msword"
                        hidden
                      />
                      <span className="ml-2">
                        {file ? file.name : "Choose a file"}
                      </span>
                    </label>
                  </span>
                  <span className="col-4">
                    <button
                      onClick={handleFileUpload}
                      className="btn btn-primary"
                      style={{ float: "right" }}
                    >
                      Upload
                    </button>
                  </span>
                </div>
              )}
              {data.resume && (
                <div className="row m-1">
                  <div class="col-12 alert alert-primary" role="alert">
                    <span style={{ float: "left" }}>
                      File Uploaded Successfully
                    </span>
                    <span style={{ float: "right" }}>
                      <a
                        className="btn btn-primary btn-sm"
                        href={data.resume}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Preview
                      </a>
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-6">
              {perc !== 0 ? (
                <div className="progress mt-3" style={{ width: "100%" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: perc + "%" }}
                    aria-valuenow={perc}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                    {perc}%
                  </div>
                  <p></p>
                </div>
              ) : null}
            </div>
            <div className="col-12 mt-5" >
              <div className="text-center" style={{ float: "right" }}>
                <button
                  className="btn btn-primary text-uppercase"
                  onClick={handleSubmit}
                >
                  <b>Send Application</b>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
