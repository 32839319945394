import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/img/icon/logo-nobg.png";

export default function Navbar() {
  const navigate1 = useNavigate();

  const navigate = (link) => {
    navigate1(link);
    window.scrollTo(0, 0);
    document.getElementById("navbar-toggler").click();

  }

  const Links = [
    { name: "Home", link: "/" },
    { name: "About Us", link: "/about" },
    { name: "Products", link: "/products" },
    { name: "Services", link: "/services" },
    { name: "Internships", link: "/internships" },
    { name: "Careers", link: "/careers" },
    { name: "Contact Us", link: "/contact" },
  ];

  return (
    <div className="container-fluid bg-white position-relative">
      <nav className="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0">
        <a href="javascript:void(0)" onClick={() => navigate("/")} className="navbar-brand text-secondary">
          <img
            src={Logo}
            alt="logo"
            className="img-fluid"
            style={{ height: "50px" }}
          />
        </a>
        <button
          type="button"
          id="navbar-toggler"
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ml-auto py-0 pr-3 border-right">
            {Links.map((link, index) => {
              return (
                <button
                  onClick={() => navigate(link.link)}
                  key={index}
                  className="nav-item nav-link"
                >
                  {link.name}
                </button>
              );
            })}
            <button>
              <a
                href="https://1000projects.org"
                className="nav-item nav-link"
                target={"_blank"}
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </button>
          </div>
          <div className="align-items-center pl-4">
            <a
              href="https://admin.1000projects.com"
              className="btn btn-primary btn-block text-uppercase py-2 px-3 text-white"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Login Now
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}
