import React from "react";
import Section from "../common/Section";
import InternshipsHome from "./InternshipsHome";
import img from "../../assets/img/section_4.png"

export default function Internships() {
  return (
    <>
      <Section name="Internships" img={img} />
      <InternshipsHome />
    </>
  );
}
