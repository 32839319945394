import React from 'react'
import { useNavigate } from "react-router-dom";

export default function ProjectCard({ item }) {
    const navigate = useNavigate();
    const handleClick = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };
    return (
        <div className="col-12 mb-3">
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title text-center">{item.project_name}</h5>
                </div>
                <div className="row no-gutters align-items-center">
                    <div className="col-md-4">
                        <img src={item.project_img} className="card-img" alt={item.project_name} />
                        <div className="card-body">
                            <p className="card-text text-center">
                                {item.attachment && (
                                    <a
                                        href={item.attachment}
                                        className="btn btn-primary btn-sm m-1"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <b>Attachment</b>
                                    </a>
                                )} <button className="btn btn-primary btn-sm m-1" onClick={() => handleClick("/contact")}>Request Demo</button>
                            </p>
                        </div>

                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <b>Description: </b>
                            <p className="card-text  project-card">
                                {item.project_description}
                            </p>
                            <p className="card-text">
                                <b>Technology: </b><br />
                                {item.technology}
                                <br /><br />
                                <b>Features: </b><br />
                                {item.project_features1}
                                <br />
                                {item.project_features2}
                                <br />
                                {item.project_features3}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
