import React from "react";
import Section from "../common/Section";
import ContactHome from "./ContactHome";
import ContactRow from "../contact/ContactRow";
import img from "../../assets/img/section_6.png"

export default function Contact() {
  return (
    <>
      <Section name="Contact Us" img={img} />
      <ContactHome />
      <ContactRow />
    </>
  );
}
