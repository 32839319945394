import React from 'react';
import Application from './Application';

export default function Cdata({ data }) {
    return (
        <div className='row'>
            <div className='col-12 my-5'>
                <div className="align-items-center bg-light rounded p-3">
                    <small style={{ float: "right" }}>
                        Added: {data.timestamp}
                    </small>
                    <br />
                    <h6 className="text-uppercase text-center mb-3">{`${data.job_id} | ${data.title}`}</h6>
                    <p style={{ fontSize: "0.8rem", color: "black" }}>
                        <b>Description: </b><br/>
                        {data.description}<br/>
                        <br />
                        <b>Skills Required: </b><br/>
                        {data.skills}<br/>
                        <br />
                        <b>Experience: </b>
                        {data.experience}
                        <br />
                        <b>Location: </b>
                        {data.location}
                        <br />
                        <b>Salary: </b>
                        {data.salary}
                        <br />
                        <div style={{ float: "right" }}>
                            {data.attachment && (
                                <a
                                    href={data.attachment}
                                    className="btn btn-primary btn-sm"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <b>Attachment</b>
                                </a>
                            )}
                        </div>
                    </p>
                </div>
            </div>
            <div className='col-12'>
                <Application post={data.title} />
            </div>
        </div>
    )
}
