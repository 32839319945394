import React from "react";
import axios from "axios";
import Jobs from "./Jobs";
import Application from "./Application";
import NoRecord from "../common/NoRecord";
import Loader from "../common/Loader";

export default function Careers({ setData }) {
  const [jobs, setJobs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);


  React.useEffect(() => {
    axios.get("https://server.1000projects.com/job/All").then((res) => {
      setJobs(res.data);
      setLoading(true);
    });
  }, []);

  return (
    <div className="container">
      <h1 className="display-4 text-uppercase text-center mb-5">Job Openings</h1>
      <div className="row mb-5">
        {!loading ? (
          <Loader />
        ) : loading && jobs.length === 0 ? (
          <div className="col-12">
            <NoRecord />
          </div>
        ) : (
          <Jobs setData={setData} jobs={jobs} />
        )}
      </div>
      <h1 className="display-4 text-uppercase text-center mb-5">Submit Your Resume</h1>
      <div className="row">
        <Application />
      </div>
    </div>
  );
}
