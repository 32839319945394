import React from "react";
import { useNavigate } from "react-router-dom";

export default function ServicesHome() {
  const path = window.location.pathname;
  const navigate = useNavigate();

  const handleNavigate = (link) => {
    navigate(link);
    window.scrollTo(0, 0);
  };

  const Links = [
    { name1: "Recruitment", name2: "Services", icon: "fa-user" },
    { name1: "Consulting", name2: "Services", icon: "fa-handshake" },
    { name1: "Outsourcing", name2: "Services", icon: "fa-user-plus" },
    { name1: "Technology", name2: "Solutions", icon: "fa-laptop" },
    { name1: "Industry", name2: "Verticals", icon: "fa-industry" },
    { name1: "Corporate", name2: "Training", icon: "fa-building" },
  ];

  return (
    <div className="container services">
      <h1 className="display-4 text-uppercase text-center mb-5">
        Our Top Services
      </h1>
      <div className="row text-center justify-content-center">
        {Links.map((link, index) => {
          return (
            <div className="col-md-3 col-12 mb-2">
              <div className="service-item rounded p-5 mb-4">
                <i
                  className={`fa fa-solid fa-3x ${link.icon} text-primary mb-4`}
                />
                <h4 className="text-uppercase mb-4">
                  {link.name1}
                  <span className="d-block text-body">{link.name2}</span>
                </h4>
              </div>
            </div>
          );
        })}
        <br />
        {path === "/" && (
          <div className="col-12 text-center">
            <button
              onClick={() => handleNavigate("/services")}
              className="btn btn-primary text-uppercase mt-1 py-3 px-5"
            >
              <b>More Services</b>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
