import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function CorporateTraining() {
    const navigate = useNavigate();
    return (
        <div className='row'>
            <div className="col-12 mb-3">
                <span className="btn btn-primary btn-sm mb-5" onClick={() => navigate("/services")} style={{ float: "right" }}>Back</span>
                <div className="align-items-center bg-light rounded px-md-5 px-3 py-5">
                    <h3 className="text-uppercase mb-3">5. Corporate Training</h3>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        Organizations need business training programs to help employees
                        add to their skill set and expand their knowledge base. Such
                        training programs are vital to any organization. It improves
                        employee satisfaction levels and boost employee morale. At the
                        same time, they pave the way for innovation, enhanced
                        productivity and employee performance
                        <br />
                        Here are a few challenges that enterprises face when it comes to
                        the need for effective corporate training.
                        <br />
                        <ul>
                            <li>
                                Motivating employees to enroll for the training programs.
                            </li>
                            <li>
                                Effective planning and consistency in the scheduling of
                                training programs.
                            </li>
                            <li>Incorporating personalized evaluation systems</li>
                            <li>
                                Effective tracking of participation and post assessments.
                            </li>
                            <li>
                                Engaging the participants and holding their attention during
                                the training program.
                            </li>
                        </ul>
                        We offer both- theoretical and practical knowledge to help the
                        business employees so that they can easily work with the office
                        workflow automation. Our strongly-held core values are always
                        with us throughout the training session and we remain dedicated
                        and ambitious to serve you better. Our bespoke training program
                        made us a reputable company, often picked up by the corporate
                        and business professionals. We assure, you will be delighted
                        with an effective training program.
                    </p>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="align-items-center bg-light rounded px-md-5 px-3 py-5">
                    <h5 className="text-uppercase mb-3">About Corporate Training</h5>
                    <h6>What is corporate training?</h6>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        Corporate training, also known as Corporate Education or more
                        recently Workplace Learning, is a system of activities designed
                        to educate employees. While it helps employers, it is also
                        beneficial for employees as it helps them obtain and hone
                        knowledge and skills to progress professionally and personally.
                    </p>
                    <h6>Corporate Training Important For Enterprises</h6>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        Corporate training benefits organizations and employees alike by
                        ensuring swift acquisition of the capabilities needed to
                        accomplish corporate goals and success, as well as improving
                        teamwork, employee satisfaction, and retention, as well as each
                        employee's personal skillset, job value, and career development.
                        According to a study by the American Society of Training and
                        Development, corporate training has helped enterprises enjoy
                        more than 24% higher profit margins. By adding high-value skills
                        and certifications to their offerings, corporate training has
                        even enabled some organizations to generate more than 200% more
                        revenue from each upskilled employee. Corporate training
                        programs also improve employer reputation and the ability to
                        attract new talent.
                    </p>
                    <h6>Sets 1000Projects Corporate Training Program Apart</h6>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        As the #1 online bootcamp provider for digital skills, 1000
                        Projects blended learning delivery model is proven to lead to
                        better and more consistent learning outcomes, as well as higher
                        engagement rates among learners. With a focus on digital economy
                        skills most in demand at modern enterprises, all 1000Projects
                        curriculum is developed and updated by leading experts in each
                        field and aligned to authoritative certification bodies.
                        Flexible and personalized skills training means organizations
                        have access to interactive instructor-led live virtual
                        classrooms, online self-paced learning, hands-on projects and
                        online labs, and a learning management system (LMS) dashboard
                        that provides visibility and support for managers and learners
                        alike. These features drive our industry-leading course
                        completion rates (Above 70%) and help our enterprise clients
                        cost-effectively upskill their teams through corporate training
                        programs.{" "}
                    </p>
                    <h6>
                        Skills Are Covered In 1000Projects Corporate Training Program
                    </h6>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        1000Projects provides corporate training in more than 15
                        specialized programs that are focused around four core industry
                        verticals, including Digital Business, Technology, Data Science
                        & Artificial Intelligence (AI), and Digital Operations. Our
                        corporate training courses include Digital Marketing, Business &
                        Leadership, Cloud Computing, DevOps, Cyber Security, Software
                        Development, Data Science & AI, Big Data, Agile & Scrum, IT
                        Service & Architecture, Project Management, and Quality
                        Management. These corporate training programs are further
                        divided into specific courses and role-based learning paths that
                        enterprises can choose from.
                    </p>
                    <h6>
                        Is There Any Minimum Team Size Requirement for 1000Projects
                        Corporate Training Program
                    </h6>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        There is no minimum team size required to participate in 1000
                        Projects corporate training program. No matter what your team
                        size is, our Customer Success experts will help you develop a
                        learning path that’s ideal for your organization and employees,
                        whether you need to train one single team member or an entire
                        division.{" "}
                    </p>
                    <h6>Online Bootcamp</h6>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        Online bootcamps are accelerated, intensive study programs that
                        enable students to learn in-demand digital skills in a short
                        period of time.
                    </p>
                    <h6>Workplace Learning Today</h6>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        Better workplace learning is more in demand than ever. According
                        to a survey conducted by LinkedIn, 94% of employees stated that
                        they would commit to staying at a company longer if the company
                        invested in their development. Complimenting this statistic is
                        the fact that 90% of top management also believe that investing
                        in their employees’ career development is a positive requirement
                        for the growth of the company.
                        <br />
                        That settles it then. Both the employees and employers see the
                        importance in the career development of their workforce. What
                        next? Creating 'standardized' training programs for all your
                        employees will sure, present information in front of them but
                        that does not guarantee transfer of knowledge and employee
                        engagement that will translate into growth for the company.
                        <br />
                        More importantly, your employees have varied roles, experience,
                        knowledge, & abilities. Designing your training program to be
                        specific to each one of them will improve learning efficiency
                        and increase ROI. Well-structured L&D programs build the desired
                        knowledge and skills that will impact individual employee
                        performance and increase job satisfaction, which often results
                        in higher employee retention.
                    </p>
                </div>
            </div>
        </div>
    )
}
