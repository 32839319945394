import React from "react";
import Hero from "./Hero";
import AboutHome from "../about/AboutHome";
import ServicesHome from "../services/ServicesHome";
import ProjectsHome from "../projects/ProjectsHome";
import InternshipsHome from "../internships/InternshipsHome";
import ContactHome from "../contact/ContactHome";

export default function Home() {
  return (
    <>
      <Hero />
      <AboutHome />
      <ServicesHome />
      <ProjectsHome />
      <InternshipsHome />
      <ContactHome />
    </>
  );
}
