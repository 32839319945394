import React from "react";


export default function Jobs({ jobs, setData }) {
  return (
    <>
      <div className="row">
        {jobs.map((job) => {
          return (
            <><div className="col-md-6 col-12 mb-3">
              <div className="list-group">
                <div className="align-items-center bg-light rounded p-3 jobappliaction">
                  <small style={{ float: "right" }}>
                    Added: {job.timestamp}
                  </small>
                  <br />
                  <h6 className="text-uppercase mb-3">{`${job.job_id} | ${job.title}`}</h6>
                  <p style={{ fontSize: "0.8rem", color: "black" }}>
                    <b>Description: </b><br />
                    {job.description}
                    <br /><br />
                    <b>Skills Required: </b><br />
                    {job.skills}<br />
                    <br />
                    <b>Experience: </b>
                    {job.experience}
                    <br />
                    <b>Location: </b>
                    {job.location}
                    <br />
                    <b>Salary: </b>
                    {job.salary}
                    <br />
                    <div style={{ float: "right" }}>
                      {job.attachment && (
                        <a
                          href={job.attachment}
                          className="btn btn-primary btn-sm"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <b>Attachment</b>
                        </a>
                      )}
                      <a
                        href="javascript:void(0)"
                        className="btn btn-primary btn-sm ml-2 mt-1"
                        onClick={() => setData(job)
                        }
                      >
                        <b>Apply</b>
                      </a>
                    </div><br />
                  </p>
                </div></div>
            </div>
            </>
          );
        })}
      </div>
    </>
  );
}
