import React from 'react'
import Section from '../common/Section';
import ProjectsCard from './ProjectsCard';
import img from "../../assets/img/section_2.png"
export default function Projects() {
  return (
    <>
      <Section name="Our Products" img={img} />
      <ProjectsCard />
    </>
  )
}
