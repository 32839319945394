import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Consulting() {
    const navigate = useNavigate();
    return (
        <div className='row'>
            <div className="col-12 mb-3">
                <span className="btn btn-primary btn-sm mb-5" onClick={() => navigate("/services")} style={{ float: "right" }}>Back</span>
                <div className="align-items-center bg-light rounded px-md-5 px-3 py-5">
                    <h3 className="text-uppercase mb-3">3. Consulting</h3>
                    <p style={{ fontSize: "1rem", color: "black" }}>
                        We offers services to streamline IT strategy creating,
                        information security assurance and system integration, ensure
                        smooth and effective digital transformation and improve digital
                        customer experience. Our approach is to focus on the client’s
                        strategy and long-term goals, so we skip the noise of fleeting
                        trends and equip the business with reliable and agile technology
                        to achieve enduring outcomes.
                        <br />
                        Enabling your business to maintain to be a successful and
                        profitable organisation through continuous support, development
                        and partnership
                        <br />
                        Our Service Offerings Include:
                        <ul>
                            <li>Application Development & Maintenance.</li>
                            <li>Enterprise Services.</li>
                            <li>Data Analytics & Decision Making</li>
                            <li>Testing Services.</li>
                            <li>Public Cloud Applications.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    )
}
