import React from "react";
import { useNavigate } from "react-router-dom";

export default function Section({ name, img }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="card text-light" style={{ height: "200px" }}>
        <img src={img} className="card-img" alt="..." style={{ height: "200px" }} />
        <div className="card-img-overlay text-center">
          <h1 className="display-3 text-uppercase m-3 text-light">{name}</h1>
          {/* <div className="d-inline-flex text-white" style={{display:"none"}}>
            <h6 className="text-uppercase m-2">
              <a
                onClick={() => navigate("/")}
                className="text-white"
                href="javascript:void(0)"
              >
                Home  /
              </a>
            </h6>
            <h6 className="text-uppercase m-0 text-light m-2">{name}</h6>
          </div> */}
        </div>
      </div>
    </>
  );
}